import Vue from 'vue'
import VueRouter from 'vue-router'
import { validatenull,vaildatePc } from '@/utils/validate'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'index',
    component: () => import(/* webpackChunkName: "home" */ '../views/index'),
    meta: {
      title: '大爱无声全民祈福',
      isToken:false,
      content:{
        keywords:'大爱无声全民祈福',
        description:'大爱无声全民祈福',
      },
    }
  },
  {
    path: '/detail',
    name: 'detail',
    component: () => import(/* webpackChunkName: "detail" */ '../views/detail'),
    meta: {
      title: '大爱无声全民祈福',
      isToken:false
    }
  },
  {
    path: '/space',
    name: 'space',
    component: () => import(/* webpackChunkName: "detail" */ '../views/space'),
    meta: {
      title: '大爱无声全民祈福',
      isToken:false,
      content:{
        keywords:'大爱无声全民祈福',
        description:'大爱无声全民祈福',
      },
    }
  }
]
const router = new VueRouter({
  mode: 'hash',
  base: process.env.BASE_URL,
  routes
})
router.beforeEach((to, from, next) => {
  /* 路由发生变化修改页面title */
  if (to.meta.title) {
    document.title = to.meta.title;
  }
  let query = to.query;
  let params = ''
  if (!validatenull(query)) { 
    for (let key in query) { 
      params+= key+'='+ query[key]+'&'
    }
  }
  console.log(params)
  //页面切换滚动到顶部
  document.body.scrollTop = 0
  document.documentElement.scrollTop = 0// firefox
  if (vaildatePc()) {
    next();
  } else { 
    location.href="/mobile.html?"+params
  }
});
export default router
